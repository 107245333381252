<template>
  <div class="inside-page-content">
    <div class="paper">
      <div class="list">
        <el-table class="bg-rgba-w-1" :data="dataList" style="width: 90%">
          <el-table-column prop="patentName" label="名称" width="200" align="center" :show-overflow-tooltip="true"/>
          <el-table-column prop="buyerCompany" label="买方信息" width="300" align="center"/>
          <el-table-column prop="sellerCompany" label="卖方信息" width="300" align="center"/>
          <el-table-column prop="transactionAmount" label="成交额" align="center">
            <template #default="scope">
              {{scope.row.transactionAmount}}万
            </template>
          </el-table-column>
          <el-table-column label="状态" align="center">
            <template #default="scope">
              <label v-if="scope.row.status == 1" style="color: #00d9ff;" >完成</label>
              <label v-else style="color: #00d9ff;" >交易中</label>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import TopFilter from './components/topFilter.vue'
export default {
  name: 'patentDealList',
  components: {
    TopFilter
  },
  data() {
    return {
      dataList:[],
      current: 0,
      keyword: "",
      tableData:[
        {
          name: '实用新型****专利转让',
          buy: '绵阳狻****科技有限公司',
          sail: '四川旭***科技有限公司',
          num: '***万',
          status: '1',
        },
        {
          name: '实用新型****专利转让',
          buy: '绵阳狻****科技有限公司',
          sail: '四川旭***科技有限公司',
          num: '***万',
          status: '1',
        },
        {
          name: '实用新型****专利转让',
          buy: '绵阳狻****科技有限公司',
          sail: '四川旭***科技有限公司',
          num: '***万',
          status: '1',
        },
        {
          name: '实用新型****专利转让',
          buy: '绵阳狻****科技有限公司',
          sail: '四川旭***科技有限公司',
          num: '***万',
          status: '1',
        },
      ]
    }
  },
  computed:{
  },
  created(options) {
    this.getData()
    let isScroll = true // 也可以定义到data里
    this.$nextTick(() => {
      let div = document.getElementsByClassName('el-table__body-wrapper')[0]
      div.style.height = '300px'
      div.addEventListener('mouseenter', () => {
        isScroll = false
      })
      div.addEventListener('mouseleave', () => {
        isScroll = true
      })
      let t = document.getElementsByClassName('el-table__body')[0]
      setInterval(() =>{
        if(isScroll) {
          let data = this.dataList[0]
          setTimeout(() => {
            this.dataList.push(data)
            t.style.transition = 'all .5s'
            t.style.marginTop = '-41px'
          }, 500)
          setTimeout(() =>{
            this.dataList.splice(0,1)
            t.style.transition = 'all 0s ease 0s'
            t.style.marginTop = '0'
          }, 1000)
        }
      }, 2500)
    })
  },
  mounted() {

  },
  methods: {
    getData() {
      this.$api.intellectualProperty.tradeList().then((res) => {
        this.dataList = res.data.rows
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
/deep/.el-table .cell.el-tooltip {
  white-space: nowrap!important;
  min-width: 50px;
}
/deep/.el-table .cell {
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
  line-height: 23px;
  padding-left: 10px;
  padding-right: 10px;
  color: black;
}
/deep/.el-table th.el-table__cell {
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background:  #CDD2ED;
}
/deep/.el-table tr {
  background:  rgba(255, 255, 255, 0.1);
}
.list {
  display: flex;
  justify-content: space-around;
  margin: 20px 40px;
  .item {
    width: var(--itemWidth);
    padding: 10px;
    box-sizing: border-box;
    height: 200px;
    background: white;
    text-align: center;
    .title{
      border-bottom: 1px dashed grey;;
      padding-top: 5px;
      display: block;
      color: black;
      font-family: "Arial Black";
      //padding: 50px 0;
    }
    .content{
      display: flex;
      flex-direction: row;
      padding-top: 10px;
      .logo {
        width: 40%;
        flex: 1;
        display: block;
        margin-top: 35px;
      }
      .info{
        flex: 1;
        width: 40%;
        padding-top: 5px;
        text-align: left;
        float: right;
        .price {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: smaller;
          padding: 10px 0;
          display: flex;
          flex-direction: row;
          .name{
            color: grey;
          }
          .value{
            float: right;
            color: black;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
</style>
